.not-found-container {
    text-align: center;
    padding: 50px;
  }
  
  .not-found-title {
    font-size: 48px;
    color: #ff5733;
  }
  
  .not-found-text {
    font-size: 18px;
    color: #777;
  }
  